/* 
 * @Author: 曹俊杰 
 * @Date: 2021-02-05 14:19:20
 * @Module: 中能担保入口
 */
 <template>
  <div>
    <img src="@/static/homepage/Lark20210205-105908.png"
         mode="aspectFill"
         class="topup-box hover"
         @click="click" />
    <toAppModule ref="toAppModule"
                 title="请下载网红头条APP使用"
                 info="此功能需要在网红头条APP内操作" />
  </div>
</template>
 <script>
import toAppModule from "@/views/college/module/toAppModule"
export default {
  components: {
    toAppModule
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    click () {
      this.$refs.toAppModule.showModule()
    }
  },
};
 </script>
 <style lang='scss' scoped>
.topup-box {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 56px;
  background: #000;
  border-radius: 4px;
  margin-top: 16px;
}
</style>