/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-31 16:16:49
 * @Module: 关注推荐
 */
 <template>
  <div
    class="attention-box"
    v-loading="loading"
    v-if="loading || pagination.total"
  >
    <div class="attention-box-title">
      可能感兴趣的人
    </div>
    <div class="attention" v-for="(item, index) in list" :key="index">
      <div class="attention-title">
        <span class="attention-title-name">平台推荐·</span>
        <span>{{ item.recommend_reason }}</span>
      </div>

      <div class="attention-item">
        <div
          class="attention-item-l"
          @click="
            $store.dispatch('homepage/toPersonal', {
              uuid: item.user_id,
              newPage: true
            })
          "
        >
          <div style="position:relative">
            <el-avatar :size="36" :src="item.avatar" class="hover"></el-avatar>
            <img
              src="@/static/img/renzheng.gif"
              alt=""
              v-if="!(item.user_verify == null)"
              style="width:12px;height:12px;position:absolute;bottom:0px;right:1px;z-index:100"
            />
          </div>
          <div class="attention-item-l-text">
            <div :class="item.is_vip ? 'new-color' : ''">{{ item.nick_name }}</div>
            <div class="ellipsis">{{ item.user_description }}</div>
          </div>
        </div>

        <div class="attention-item-r hover" @click="_get_follow(item.user_id)">
          <span>+</span>
          <span>关注</span>
        </div>
      </div>
    </div>
    <div
      class="attention-btn hover"
      v-show="pagination.total_pages >= pagination.current_page"
      @click="onSwitch"
    >
      换一批
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      pagination: {
        total: 0,
        pageSize: 20,
        page: null,
        total_pages: 0,
        current_page: 0
      },
      list: []
    };
  },
  mounted() {
    this._get_list();
  },
  methods: {
    async _get_list(page = 1) {
      this.loading = true;
      const { status, info, data, meta } = await this.$api.friendRecommendApi({
        page
      });
      this.loading = false;
      if (status == 200) {
        this.list = data;
        this.pagination.total = meta.pagination.total;
        this.pagination.pageSize = meta.pagination.per_page;
        this.pagination.total_pages = meta.pagination.total_pages;
        this.pagination.current_page = meta.pagination.current_page;
      } else {
        this.$message.error(info);
      }
    },
    // 换一批
    onSwitch() {
      const { total_pages, current_page } = this.pagination;
      if (total_pages > current_page) {
        this._get_list(current_page + 1);
      } else {
        this._get_list(1);
      }
    },
    // 请求点赞
    async _get_follow(user_id) {
      const { status, info } = await this.$api.followApi({ user_id });
      if (status == 200) {
        this.$message.success(info);
        this._get_list();
      } else {
        this.$message.error(info);
      }
    }
  }
};
</script>
 <style lang='scss' scoped>
.attention-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 16px;
  overflow: hidden;
  min-height: 142px;
  &-title {
    line-height: 40px;
    font-family: PingFang SC;
    font-size: 14px;
    color: #333333;
    padding-left: 20px;
    border-bottom: 1px solid #eee;
  }
}
.attention {
  &-title {
    padding: 14px 0px 8px 20px;
    box-sizing: content-box;
    font-size: 12px;
    &-name {
      color: #8baade;
    }
  }
  &-item {
    width: 100%;
    height: 62px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    &-l {
      display: flex;
      font-size: 12px;
      flex: 1;
      &-text {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        div:last-child {
          color: #999999;
        }
      }
    }
    &-r {
      width: 52px;
      height: 26px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      font-size: 12px;
      line-height: 26px;
      text-align: center;
      span:first-child {
        color: #df2a29;
      }
    }
  }
  &-btn {
    width: 100%;
    background: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    color: #333333;
  }
}
.new-color {
  color: #df2a29 !important;
}
</style>