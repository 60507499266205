/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-25 14:45:14
 * @Module: 点赞特效
 */
 <template>
  <div class="praise">
    <div class="iconfont icondianzanhou"
         style="font-size:70px;"></div>
    <div class="num">+1</div>
    <audio src="@/static/mp3/zan_Audio.mp3"
           autoplay />
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
@keyframes heart-bounce {
  20% {
    // -webkit-transform: scale(0.7);
    // transform: scale(0.7);
    // -webkit-transform: rotate(30deg);
    // transform: rotate(30deg);
  }
  40% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  // 50% {
  //   -webkit-transform: scale(1);
  //   transform: scale(1);
  // }
  60% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes num {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(30px, -60px);
    opacity: 0;
  }
}
.praise {
  width: 100vw;
  height: 100vh;
  color: #df2a29;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  animation: heart-bounce 1s cubic-bezier(0.7, 0, 0.3, 1);
  transform: rotate(15deg);
}
.num {
  position: absolute;
  font-size: 30px;
  animation: num 1.5s;
}
</style>