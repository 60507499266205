/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-17 11:27:47
 * @Module: 首页右侧搜索按钮
 */
 <template>
  <div class="seachBtn-box"
       @click="searchBtn">
    <el-input placeholder="请输入关键词、用户"
              readonly
              suffix-icon="el-icon-search" />
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    searchBtn () {
      this.$store.commit('layouts/changeSearchDialogVisible', true)
    },
  },
};
 </script>
 <style lang='scss' scoped>
.seachBtn-box {
  margin-bottom: 16px;
}
</style>